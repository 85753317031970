@font-face {
  font-family: 'Gilroy';
  src: url('./gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./gilroy/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
